"use client"; // ensures useEffect and browser APIs are available

import React, {useEffect, useRef, useState} from "react";

interface VideoSource {
  src: string;
  type: string;
}

interface VideoPlayerClientProps {
  videoSources: VideoSource[];
  poster: string;
  playOnScroll?: boolean;
}

export default function VideoPlayerClient({videoSources, poster, playOnScroll = true}: VideoPlayerClientProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const isProduction = process.env.NODE_ENV === "production";
  const [hasDelayed, setHasDelayed] = useState(false);

  const botPatterns = [/bot/i, /crawl/i, /spider/i, /googlebot/i, /bingbot/i, /yandexbot/i, /duckduckbot/i, /baiduspider/i];
  const userAgent = navigator.userAgent;
  const isBot = botPatterns.some((pattern) => pattern.test(userAgent));

  useEffect(() => {
    // If it’s a bot or no video element, do nothing
    if (isBot || !videoRef.current) return;

    if (playOnScroll) {
      // Use IntersectionObserver to handle "scroll into view" playback
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Only delay on the first time the video intersects
              if (!hasDelayed) {
                setHasDelayed(true);
                setTimeout(() => {
                  videoRef.current?.play().catch((err) => console.warn("Video playback failed:", err));
                }, 1000);
              } else {
                // On subsequent entries, play immediately
                videoRef.current?.play().catch((err) => console.warn("Video playback failed:", err));
              }
            } else {
              // Pause when out of view
              videoRef.current?.pause();
            }
          });
        },
        {threshold: 0.5} // Adjust as needed
      );

      observer.observe(videoRef.current);

      return () => {
        observer.disconnect();
      };
    } else if (isProduction) {
      // If NOT playing on scroll, delay playback on mount by 1 second
      const timer = setTimeout(() => {
        videoRef.current?.play().catch((err) => console.warn("Video playback failed:", err));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isBot, playOnScroll, isProduction, hasDelayed]);

  return (
    <video ref={videoRef} loop muted playsInline preload="none" poster={poster} className="w-full h-full object-cover">
      {!isBot && videoSources.map((source, index) => <source key={index} src={source.src} type={source.type} />)}
      Your browser does not support the video tag.
    </video>
  );
}
