"use client";

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

import {sendGAEvent} from "@next/third-parties/google";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";

type TextSizeOption = "small" | "medium" | "large" | "xsmall" | "xxsmall";
type DynamicEventButtonProps = {
  title: string;
  url: string;
  eventName: string;
  icon?: IconDefinition;
  iconSize?: SizeProp;
  textSize?: TextSizeOption;
};

function getTextSizeClass(size: TextSizeOption = "medium"): string {
  switch (size) {
    case "xxsmall":
      return "text-lg sm:text-xl md:text-2xl";
    case "xsmall":
      return "text-xl sm:text-2xl md:text-3xl";
    case "small":
      return "text-2xl sm:text-3xl md:text-4xl";
    case "large":
      return "text-4xl sm:text-5xl md:text-6xl";
    case "medium":
      return "text-3xl sm:text-4xl md:text-5xl";
    default:
      return "text-3xl sm:text-4xl md:text-5xl";
  }
}

export default function DynamicEventButton({title, url, eventName, icon, iconSize = "xs", textSize = "medium"}: DynamicEventButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Optional Yandex Metrika example
    if (typeof window !== "undefined" && typeof window.ym === "function") {
      window.ym(94764572, "reachGoal", eventName);
    }

    // Google Analytics or other analytics
    sendGAEvent("event", eventName);

    // Open the specified URL in a new browser tab
    window.open(url, "_blank");
  };

  const textSizeClasses = getTextSizeClass(textSize);
  if (textSize == "small") iconSize = "2xs";

  return (
    <button
      onClick={handleClick}
      rel="noopener noreferrer"
      className={`
        inline-flex items-center justify-center 
        px-8 py-4 bg-gold text-black font-light  shadow-md 
        font-tungsten-semibold uppercase 
        hover:bg-black hover:text-gold hover:shadow-lg 
        transform hover:scale-105 transition-all duration-300 ${textSizeClasses}
      `}>
      {title}
      {icon && (
        <span className="pl-4">
          <FontAwesomeIcon icon={icon} size={iconSize} />
        </span>
      )}
    </button>
  );
}
